import {Injectable} from '@angular/core';
import {SourceEnhancementProgram} from './declarations';
import {ProgramControllerService, ProgramResponse} from '@matchsource/api-generated/common';
import {Observable} from 'rxjs';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';

@Injectable({
  providedIn: 'root',
})
export class EbsProgramsApiService {
  constructor(private readonly programControllerService: ProgramControllerService) {}

  getBpsEligibleForProgram(enhancementProgramName: SourceEnhancementProgram): Observable<ProgramResponse[]> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingProgramData);
    const requestOptions = {context: context()};

    return this.programControllerService.getAllCustomersProgram({programName: enhancementProgramName}, requestOptions);
  }
}
