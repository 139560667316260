import {Action, Selector, State, StateContext} from '@ngxs/store';
import {BusinessPartiesApiService} from '@matchsource/api/business-parties';
import {BusinessPartyModel} from '@matchsource/models/business-party';
import {Injectable} from '@angular/core';
import {
  addOrReplaceEntities,
  defaultEntitiesState,
  EntitiesStateModel,
  loadedEntities,
  loadingEntities,
} from '@matchsource/store/core';
import {catchError, tap} from 'rxjs/operators';
import {compose} from '@ngxs/store/operators';
import {AddOrReplaceBusinessParties, GetBusinessParties} from './business-parties.action';
import {of, zip} from 'rxjs';
import {GetCountries, CountriesState} from '@matchsource/store/countries';
import {CountryModel} from '@matchsource/models/country';
import {EbsProgramsApiService, SourceEnhancementProgram} from '@matchsource/api/ebs-programs';
import {ProgramResponse} from '@matchsource/api-generated/common';
import {toMap} from '@matchsource/utils';
import {USER_PERMISSIONS} from '@matchsource/models/permissions';
import {PermissionsService} from '@matchsource/store/permissions';

export type ExtendedBusinessPartyModel = BusinessPartyModel & {
  eligibleForGamida?: boolean;
  eligibleForEps?: boolean;
};

export type BusinessPartiesStateModel = EntitiesStateModel<ExtendedBusinessPartyModel>;

const extendedBusinessParties = (
  bps: BusinessPartyModel[],
  epsList: ProgramResponse[],
  gamidaList: ProgramResponse[]
) => {
  const epsMap = toMap(epsList, 'bpGuid');
  const gamidaMap = toMap(gamidaList, 'bpGuid');

  return bps.map(bp => ({
    ...bp,
    eligibleForGamida: bp.id in gamidaMap,
    eligibleForEps: bp.id in epsMap,
  }));
};

@State<BusinessPartiesStateModel>({
  name: 'businessParties',
  defaults: {...defaultEntitiesState<BusinessPartyModel>()},
})
@Injectable()
export class BusinessPartiesState {
  constructor(
    private readonly businessPartyApi: BusinessPartiesApiService,
    private readonly ebsProgramsApiService: EbsProgramsApiService,
    private readonly permissionsService: PermissionsService
  ) {}

  @Selector([BusinessPartiesState, CountriesState.loading])
  static loading({loading}: BusinessPartiesStateModel, countriesLoading: boolean) {
    return loading || countriesLoading;
  }

  @Selector([BusinessPartiesState, CountriesState.map])
  static map(state: BusinessPartiesStateModel, countries: MsApp.Dictionary<CountryModel>) {
    return Object.entries(state.entities).reduce((map, [key, bp]) => {
      map[key] = {
        ...bp,
        country: countries[bp.countryCode],
      };

      return map;
    }, {});
  }

  @Action(GetBusinessParties)
  getAll(ctx: StateContext<BusinessPartiesStateModel>) {
    const state = ctx.getState();

    if (state.loaded || state.loading) {
      return;
    }

    const hasEpsAccess = this.permissionsService.hasPermissions(USER_PERMISSIONS.SOURCE_ENHANCEMENT_PROGRAMS_VIEW);

    ctx.setState(loadingEntities(true));

    return zip(
      this.businessPartyApi.getBPs(),
      hasEpsAccess ? this.ebsProgramsApiService.getBpsEligibleForProgram(SourceEnhancementProgram.Eps) : of([]),
      hasEpsAccess ? this.ebsProgramsApiService.getBpsEligibleForProgram(SourceEnhancementProgram.Gamida) : of([]),
      ctx.dispatch(new GetCountries())
    ).pipe(
      catchError(error => {
        ctx.patchState({
          error,
          loading: false,
          loaded: true,
        });
        return of([[], [], []]);
      }),
      tap(([bps, epsList, gamidaList]) =>
        ctx.setState(
          compose(
            addOrReplaceEntities<BusinessPartyModel, BusinessPartiesStateModel>(
              'id',
              extendedBusinessParties(bps, epsList, gamidaList)
            ),
            loadedEntities(true),
            loadingEntities(false)
          )
        )
      )
    );
  }

  @Action(AddOrReplaceBusinessParties)
  addOrReplace(ctx: StateContext<BusinessPartiesStateModel>, {bps}: AddOrReplaceBusinessParties) {
    ctx.setState(addOrReplaceEntities<BusinessPartyModel, BusinessPartiesStateModel>('id', bps));
  }
}
